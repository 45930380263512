/* eslint-disable camelcase */
import styles from './link.module.scss';
import { useTrackingContext } from '../../../../hooks/tracking/useTrackingContext';

const Link = ({ link, name, target = '_blank', linkTrackingInfo = {} }) => {
  const { trackInternalLink } = useTrackingContext();
  const onLinkClick = () => {
    if (Object.keys(linkTrackingInfo).length !== 0) {
      trackInternalLink({
        context_page_type: 'feed',
        unit_name: 'main',
        ...linkTrackingInfo,
      });
    }
  };
  return (
    <a
      className={styles.link}
      onClick={onLinkClick}
      target={target}
      href={link}
      rel="noreferrer"
    >
      {name}
    </a>
  );
};

export default Link;
